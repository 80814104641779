import React from 'react';
import './../App.css';
import { Input, Button, Select, notification } from 'antd';
import queryString from 'query-string';
import { famousList, countryList, salutationList } from './list';
import Cookies from 'universal-cookie';
import {SERVER_URL, MATCH_NON_ENGLISH, PHONE_REG} from '../constant'
import DateAddress from '../components/dateAddress';
import Logo from '../components/logo.js';

const cookies = new Cookies(); //

const { Option } = Select;

class updatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      companyEmail: '',
      designation: '',
      telephone: '',
      company: '',
      address1: '',
      address2: '',
      postCode: '',
      city: '',
      state: '',
      // website: '',
      salutation: '',
      officeTel: '',
      appendedVisitorNumber: 0,
      appendedVisitorDOM: [],
      appendedVisitor: [],
      nameRequired: false,
      firstNameRequired: false,
      lastNameRequired: false,
      emailRequired: false,
      designationRequired: false,
      telephoneRequired: false,
      companyRequired: false,
      address1Required: false,
      postCodeRequired: false,
      salutationRequired: false,
      stateRequired: false,
      countryRequired: false,
      // websiteRequired: false,
      updateState: false,
      emailDisabled: true,
      companyEmailDisabled: false,
      companyDomain: '',
      companyEmailExist: false,
      emailExists: false,
      companyEmailExists: false,
      originalEmail: '',
      nonEnglishInputs: [],
      invalidLengthText: [],
    };
  }

  handleChange(field, value) {
    this.setState({[field]: value})
    this.setState({['invalid-length-' + field]: false})
    const match = MATCH_NON_ENGLISH.test(value)
    const array = this.state.nonEnglishInputs
    const index = array.indexOf(field)
    // const array2 = this.state.invalidLengthText
    // const index2 = array2.indexOf(field)
    // const checkList = ['designation', 'telephone', 'company', 'address1', 'postCode', 'state', 'country', 'city']
    // if (index2 >= 0 && (( value.length >= 5 && field !== 'firstName' && field !== 'lastName') || (value.length >= 1 && (field === 'firstName' || field === 'lastName')))) {
    //   array2.splice(index2, 1)
    //   this.setState({'invalidLengthText': array2})
    // } else if (index2 < 0 && value.length > 0 && ((value.length < 5 && field !== 'firstName' && field !== 'lastName') || (value.length < 1 && (field === 'firstName' || field === 'lastName')))) {
    //   array2.push(field)
    //   this.setState({'invalidLengthText': array2})
    // }
    if ( index >= 0 && !match) {
      array.splice(index, 1)
      this.setState({'nonEnglishInputs': array})
    } else if (index < 0 && match) {
      array.push(field)
      this.setState({'nonEnglishInputs': array})
    }

    if (field === 'companyEmail') {
      const companyDomain = value.includes("@") ? value.replace(/.*@/, "") : '';
      this.setState({companyDomain})
    }
    if (field === 'salutation') {
      this.setState({salutationRequired: ''})
    }
  }

  register() {
    let errorPage1 = false
    let isTextInvalidLength = this.checkTextLength()
    if (!this.state.salutation) {
      this.setState({salutationRequired: true})
      errorPage1 = true
    }
    if (this.state.firstName === '') {
      this.setState({firstNameRequired: true})
      errorPage1 = true
    }
    if (this.state.email === '') {
      this.setState({emailRequired: true})
      errorPage1 = true
    }
    if (this.state.designation === '') {
      this.setState({designationRequired: true})
      errorPage1 = true
    }
    if (this.state.telephone === '') {
      this.setState({telephoneRequired: true})
      errorPage1 = true
    }
    if (this.state.company === '') {
      this.setState({companyRequired: true})
      errorPage1 = true
    }
    if (this.state.address1 === '') {
      this.setState({address1Required: true})
      errorPage1 = true
    }
    if (this.state.postCode === '') {
      this.setState({postCodeRequired: true})
      errorPage1 = true
    }
    if (this.state.state === '') {
      this.setState({stateRequired: true})
      errorPage1 = true
    }
    if (this.state.country === '') {
      this.setState({countryRequired: true})
      errorPage1 = true
    }
    if (this.state.companyEmailExists === true || this.state.nonEnglishInputs.length > 0 || !isTextInvalidLength) {
      errorPage1 = true
    }
    if (errorPage1 === false ) {
      const that = this
      let i
      let platform
      if (this.props.location.state) {
        i = this.props.location.state.i
        platform = this.props.location.state.platform
      }
      const query = queryString.parse(this.props.location.search)
      if (Object.entries(query).length !== 0) {
        i = query.i
        platform = 'Web'
      }
      const updates = {
        i,
        platform,
        name: this.state.firstName + ' ' + this.state.lastName,
        firstName: this.state.firstName || '',
        lastName: this.state.lastName || '',
        email: this.state.email,
        companyEmail: this.state.companyEmail,
        designation: this.state.designation,
        telephone: this.state.telephone,
        company: this.state.company,
        address1: this.state.address1,
        address2: this.state.address2,
        postCode: this.state.postCode,
        state: this.state.state,
        country: this.state.country,
        // website: this.state.website,
        city: this.state.city,
        salutation: this.state.salutation,
        officeTel: this.state.officeTel
      }
      fetch(`${SERVER_URL}/update`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updates),
      }).then(() => {
        notification.open({
          message: 'Profile updated',
          // description: 'Please go to your email and get the link',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      })
        // .then((json) => {
        //   const profile = {
        //     i: json.body,
        //   }
        //   that.props.history.push('/barcode', profile)
        // })
    }
  }

  componentDidMount() {
    let i
    if (this.props.location.state) {
      i = this.props.location.state.i
    }
    const query = queryString.parse(this.props.location.search)
    if (Object.entries(query).length !== 0) {
      i = query.i
    }
    if (typeof i !== 'undefined' && i !== '') {
      fetch(`${SERVER_URL}/visitor?i=${i}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then((json) => {
          console.log(JSON.stringify(json))
          const name = json.body.name
          let firstName = json.body.firstName || ''
          let lastName = json.body.lastName || ''
          if (name) {
            const vipName =name.split(' ')
            if (!lastName) {
              lastName = vipName.pop()
            }
            if (!firstName) {
              firstName = vipName.join(' ')
            }
          }
          json.body.name && this.setState({name: json.body.name})
          this.setState({firstName: firstName})
          this.setState({lastName: lastName})
          json.body.email && this.setState({email: json.body.email})
          json.body.companyEmail && this.setState({companyEmail: json.body.companyEmail})
          json.body.designation && this.setState({designation: json.body.designation})
          json.body.telephone && this.setState({telephone: json.body.telephone})
          json.body.company && this.setState({company: json.body.company})
          json.body.address1 && this.setState({address1: json.body.address1})
          json.body.address2 && this.setState({address2: json.body.address2})
          json.body.postCode && this.setState({postCode: json.body.postCode})
          json.body.state && this.setState({state: json.body.state})
          json.body.country && this.setState({country: json.body.country})
          // json.body.website && this.setState({website: json.body.website})
          json.body.city && this.setState({city: json.body.city})
          json.body.salutation && this.setState({salutation: json.body.salutation})
          json.body.officeTel && this.setState({officeTel: json.body.officeTel})
        })
        .catch((e) => {
          notification.open({
            message: 'No valid link / ID',
            description: 'Please go to your email and get the link',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
          this.props.history.push('/login')
        })
    }
    else {
      this.props.history.push('/login')
    }
  }

  checkEmail(email, field) {
    if (email !== '') {
      fetch(`${SERVER_URL}/checkEmail`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email: email}),
      })
        .then(response => {
          if (response.status === 400) {
            this.setState({[field]: false})
          } else {
            this.setState({[field]: true})
          }
        })
    } else {
      this.setState({[field]: false})
    }
  }

  updateVisitorProfile() {
    const updates = [{
      i: this.props.location.state.i,
      platform: this.props.location.state.platform,
      name: this.state.firstName + ' ' + this.state.lastName,
      firstName: this.state.firstName || '',
      lastName: this.state.lastName || '',
      email: this.state.email,
      companyEmail: this.state.companyEmail,
      designation: this.state.designation,
      telephone: this.state.telephone,
      company: this.state.company,
      address1: this.state.address1,
      address2: this.state.address2,
      postCode: this.state.postCode,
      state: this.state.state,
      country: this.state.country,
      // website: this.state.website,
      city: this.state.city,
      salutation: this.state.salutation,
      officeTel: this.state.officeTel
    }]
    this.props.history.push('/registerp2', {updates: updates, type: 'update'})
  }

  checkTextLength() {
    let allValidLength = true
    const array = this.state.invalidLengthText
    if (this.state.firstName.trim() === '') {
      allValidLength = false
    }
    if (this.state.lastName.trim() === '') {
      // if (!array.includes('lastName')) {
      //   array.push('lastName')
      // }
      this.setState({})
      allValidLength = false
    }
    if (this.state.designation.length < 3 || array.indexOf('designation') > 0) {
      this.setState({'invalid-length-designation': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-designation': false})
    }
    console.log(this.state.telephone + ' ' + this.state.telephone.length)
    if (PHONE_REG.test(this.state.telephone) === false) {
      this.setState({'invalid-length-telephone': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-telephone': false})
    }

    if (this.state.officeTel && PHONE_REG.test(this.state.officeTel) === false) {
      this.setState({'invalid-length-officeTel': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-officeTel': false})
    }
    // if (this.state.city.length < 1 || array.indexOf('city') > 0) {
    //   if (!array.includes('city')) {
    //     array.push('city')
    //   }
    //   allValidLength = false
    // }
    if (this.state.company.length < 1 || array.indexOf('company') > 0) {
      this.setState({'invalid-length-company': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-company': false})
    }
    if (this.state.address1.length < 5 || array.indexOf('address1') > 0) {
      this.setState({'invalid-length-address1': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-address1': false})
    }
    // if (this.state.address2.length < 5 || array.indexOf('address2') < 0) {
    //   array.push('address2')
    //   allValidLength = false
    // }
    if (this.state.postCode.length === 0 || this.state.postCode.length > 6 || array.indexOf('postCode') > 0) {
      this.setState({'invalid-length-postCode': true})

      allValidLength = false
    } else {
      this.setState({'invalid-length-postCode': false})
    }
    if (this.state.state.length < 2 || array.indexOf('state') > 0) {
      this.setState({'invalid-length-state': true})
      allValidLength = false
    } else {
      this.setState({'invalid-length-state': false})
    }
    return allValidLength
  }

  render() {
    const that = this
    return (
      <div className="App">
        <header className="App-header backgroundLightGrey">
          <div className="card-header">
            {/* <img className="logoImage mb-10" src={require('../image/logo.png')} alt="MTE Logo"/> */}
            <Logo/>
            <DateAddress/>
          </div>
          <div id="p1" className="page show updatePage">
          <Select
              size="medium"
              placeholder='Select a salutation'
              onChange={(value) => this.handleChange('salutation', value)}
              className={"selectClass " + (this.state.salutationRequired === true ? 'error' : '')}
              value={this.state.salutation}
            >
              {salutationList.map(function(salutation){
                return <Option key={salutation.value} value={salutation.value}>{salutation.label}</Option>
              })}
              <Option value="" selected disabled hidden>Select a salutation</Option>
            </Select>
            {/* <div className='inputClass'>
              <Input
                className={(that.state.nameRequired && 'redPlaceholder')}
                placeholder={(that.state.nameRequired ? 'Name required' : 'Name')}
                value={this.state.name}
                onChange={(event) => this.handleChange('name', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('name') || this.state.invalidLengthText.includes('name')) && <span className='error-message'>{this.state.nonEnglishInputs.includes('name') ? 'Please enter in english.': 'Name too short! Please Enter your full name.'}</span>}
            </div> */}
            <div className='inputClass2'>
              <div className='inputField'>
                <Input
                  className={(this.state.firstNameRequired && 'redPlaceholder')}
                  placeholder={(this.state.firstNameRequired ? 'First Name required' : 'First Name')}
                  value={this.state.firstName}
                  onChange={(event) => this.handleChange('firstName', event.target.value)}
                />
                {((this.state.nonEnglishInputs.includes('firstName') || this.state.firstName === '' || this.state['invalid-length-lastName']) && this.state.firstNameRequired) && <span className='error-message'>{this.state.nonEnglishInputs.includes('firstName') ? 'Please enter in english.': 'First Name too short'}</span>}
              </div>
              <div className='inputField'>
                <Input
                  className={(this.state.lastNameRequired && 'redPlaceholder')}
                  placeholder={'Last Name'}
                  value={this.state.lastName}
                  onChange={(event) => this.handleChange('lastName', event.target.value)}
                />
                {((this.state.nonEnglishInputs.includes('lastName') || this.state.lastName === '' || this.state['invalid-length-lastName']) && this.state.lastNameRequired) && <span className='error-message'>{this.state.nonEnglishInputs.includes('lastName') ? 'Please enter in english.': 'Last Name too short'}</span>}
              </div>
            </div>
            <Input
              disabled={true}
              className={"inputClass " + (that.state.emailRequired && 'redPlaceholder')}
              placeholder={(that.state.emailRequired ? 'Email required' : 'Email')}
              value={this.state.email}
              onChange={(event) => this.handleChange('email', event.target.value)}
              onBlur={(event) => this.checkEmail(event.target.value, 'emailExists')}
            />
            {this.state.emailExists && <span className="emailExistsError">Email existed.</span>}
            {this.state.companyEmail && <Input
              disabled={true}
              className="inputClass"
              placeholder='Company Email 公司邮件'
              value={this.state.companyEmail}
              onChange={(event) => this.handleChange('companyEmail', event.target.value)}
              onBlur={(event) => this.checkEmail(event.target.value, 'companyEmailExists')}
            />}
            {this.state.companyEmailExists && <span className="emailExistsError">This email is already registered.</span>}
            <div className="inputClass">
              <Input
                className={(that.state.designationRequired && 'redPlaceholder')}
                placeholder={(that.state.designationRequired ? 'Designation required' : 'Designation')}
                value={this.state.designation}
                onChange={(event) => this.handleChange('designation', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('designation') || this.state['invalid-length-designation']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('designation')? 'Please enter in english.': 'Designation too short. Please enter your real designation.'}</span>}
            </div>
            <div className="inputClass">
            <Input
                className={(that.state.telephoneRequired && 'redPlaceholder')}
                placeholder={(that.state.telephoneRequired ? 'Handphone No. required' : 'Handphone No.')}
                value={this.state.telephone}
                onChange={(event) => this.handleChange('telephone', event.target.value)}
                maxLength={13}
              />
              {(this.state['invalid-length-telephone']) && <span className='error-message'>Invalid phone number.</span>}
            </div>
            <div className='inputClass'>
              <Input
                className={(that.state.companyRequired && 'redPlaceholder')}
                placeholder={(that.state.companyRequired ? 'Company required' : 'Company')}
                value={this.state.company}
                onChange={(event) => this.handleChange('company', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('company') || this.state['invalid-length-company']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('company') ? 'Please enter in english.': 'Company name too short! Please enter your full company name.'}</span>}
            </div>
            <div className='inputClass'>
              <Input
                className={(that.state.address1Required && 'redPlaceholder')}
                placeholder={(that.state.address1Required ? 'Address 1 required' : 'Address 1')}
                value={this.state.address1}
                onChange={(event) => this.handleChange('address1', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('address1') || this.state['invalid-length-address1']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('address1') ? 'Please enter in english.': 'Address too short! Please enter real address.'}</span>}
            </div>
            <div className='inputClass'>
              <Input
                placeholder={'Address 2'}
                value={this.state.address2}
                onChange={(event) => this.handleChange('address2', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('address2') || this.state['invalid-length-address2']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('address2') ? 'Please enter in english.': 'Address too short! Please enter real address.'}</span>}
            </div>
            <div className='inputClass'>
              <Input
                placeholder={'city'}
                value={this.state.city}
                onChange={(event) => this.handleChange('city', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('city') || this.state['invalid-length-city']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('city') ? 'Please enter in english.': 'Address too short! Please city.'}</span>}
            </div>
            <div className='inputClass'>
              <Input
                className={(that.state.postCodeRequired && 'redPlaceholder')}
                placeholder={(that.state.postCodeRequired ? 'Post Code required' : 'Post Code')}
                value={this.state.postCode}
                onChange={(event) => this.handleChange('postCode', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('postCode') || this.state['invalid-length-postCode']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('postCode') ? 'Please enter in english.': 'Invalid postcode.'}</span>}
            </div>
            <div className='inputClass'>
              <Input
                className={(that.state.stateRequired && 'redPlaceholder')}
                placeholder={(that.state.stateRequired ? 'State required' : 'State')}
                value={this.state.state}
                onChange={(event) => this.handleChange('state', event.target.value)}
              />
              {(this.state.nonEnglishInputs.includes('state') || this.state['invalid-length-state']) && <span className='error-message'>{this.state.nonEnglishInputs.includes('state') ? 'Please enter in english.': 'Invalid postcode.'}</span>}
            </div>
            <Select
              size="large"
              placeholder='Country'
              onChange={(value) => this.handleChange('country', value)}
              className="selectClass"
              value={this.state.country}
              defaultValue="Malaysia"
            >
              {famousList.map(function(country){
                return <Option key={country.code} value={country.name}>{country.name}</Option>
              })}
              <Option key={'empty'} className="emptyOption"> </Option>
              {countryList.map(function(country){
                return <Option key={country.code} value={country.name}>{country.name}</Option>
              })}
            </Select>
            {/* <Input
              className="inputClass"
              placeholder='Website'
              value={this.state.website}
              onChange={(event) => this.handleChange('website', event.target.value)}
            />
            {this.state.nonEnglishInputs.includes('website') && <span className='error-message'>Please enter in english.</span>} */}
            <div className="inputClass">
              <Input
                placeholder="Office Tel"
                value={this.state.officeTel}
                onChange={(event) => this.handleChange('officeTel', event.target.value)}
                maxLength={13}
              />
              {(this.state['invalid-length-officeTel']) && <span className='error-message'>Invalid phone number.</span>}
            </div>
            <div style={{ flexDirection: 'row', width: '90%' }}>
              <Button onClick={() => this.register()} className="registerButton">Update</Button>
            </div>
            <div style={{ flexDirection: 'row', width: '90%' }}>
              <Button onClick={() => this.updateVisitorProfile()} className="updateButton">Update visitor profile</Button>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </header>
      </div>
    );
  }
}

export default updatePage;
